<template>
  <div>
    <el-dialog title="基站 / 用传绑定部件" lock-scroll :visible.sync="visible" width="70%" top="3%">
      <div class="header">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-popover placement="right" width="600" v-model="searchPopoverVisible">
              <el-form :inline="true" :model="requestParams" class="demo-form-inline">
                <el-form-item label="单位名称">
                  <el-input v-model="requestParams.unitName" autocomplete="off" size="small" placeholder="请输入"
                            clearable></el-input>
                </el-form-item>
                <el-form-item label="设备特征码">
                  <el-input v-model="requestParams.equipmentCode" autocomplete="off" size="small" placeholder="请输入"
                            clearable></el-input>
                </el-form-item>
                <el-form-item label="设备类型">
                  <el-select v-model="requestParams.typeId" placeholder="请选择" size="small" clearable>
                    <el-option v-for="(item,i) in option.typeList" :key="i" :label="item.typeName" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="状态">
                  <el-select v-model="requestParams.status" placeholder="请选择" size="small" clearable>
                    <el-option v-for="(item,i) in [{id:1,label:'在线'},{id:0,label:'离线'}]" :key="i" :label="item.label"
                               :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" @click="searchPopoverVisible = false">取消</el-button>
                <el-button type="primary" size="mini" @click="_initTableData">查询</el-button>
              </div>
              <el-button type="primary" size="small" slot="reference" icon="el-icon-search">查询</el-button>
            </el-popover>
          </el-form-item>
          <!--          <el-form-item>
                      <el-button type="success" size="small" icon="el-icon-plus" @click.stop="$refs.bindPartDialog.show()">绑定部件
                      </el-button>
                    </el-form-item>-->
          <el-form-item>
            <el-button type="warning" size="small" icon="el-icon-refresh" @click="syncAll">一键同步</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 表格 -->
      <div class="content">
        <el-table :data="list" stripe style="width: 100%" height="500" v-loading="isLoading">
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-row :gutter="20">
                <el-col :span="12" :offset="0">
                  <el-form label-position="left" inline class="demo-table-expand">
                    <el-form-item label="设备类型" :label-width="formLabelWidth">
                      <span>{{ props.row.typeName }}</span>
                    </el-form-item>
                    <el-form-item label="设备型号" :label-width="formLabelWidth">
                      <span>{{ props.row.modelName }}</span>
                    </el-form-item>
                    <el-form-item label="所在单位" :label-width="formLabelWidth">
                      <span>{{ props.row.unitName }}</span>
                    </el-form-item>
                    <el-form-item label="安装建筑物" :label-width="formLabelWidth">
                      <span>{{ props.row.installName }}</span>
                    </el-form-item>
                    <el-form-item label="通讯方式" :label-width="formLabelWidth">
                      <span>{{ props.row.communicateTypeName }}</span>
                    </el-form-item>
                    <el-form-item label="联系电话" :label-width="formLabelWidth">
                      <span>{{ props.row.contactPhone }}</span>
                    </el-form-item>
                    <el-form-item label="状态" :label-width="formLabelWidth">
                      <el-tag type="success" size="normal" v-show="props.row.status===1">在线</el-tag>
                      <el-tag type="danger" size="normal" v-show="props.row.status===0">离线</el-tag>
                    </el-form-item>
                    <el-form-item label="图片" :label-width="formLabelWidth">
                      <el-image :src="props.row.imageUrl" fit="fill" :lazy="true"
                                :preview-src-list="[props.row.imageUrl]"></el-image>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="12" :offset="0">
                  <el-form label-position="left" inline class="demo-table-expand">
                    <el-form-item label="uuid" :label-width="formLabelWidth">
                      <span>{{ props.row.uuid }}</span>
                    </el-form-item>
                    <el-form-item label="安装时间" :label-width="formLabelWidth">
                      <span>{{ props.row.installTime|timeFormat }}</span>
                    </el-form-item>
                    <el-form-item label="设备特征码" :label-width="formLabelWidth">
                      <span>{{ props.row.equipmentCode }}</span>
                    </el-form-item>
                    <el-form-item label="基站特征码" :label-width="formLabelWidth">
                      <span>{{ props.row.stationCode }}</span>
                    </el-form-item>
                    <el-form-item label="最近数据通讯时间" :label-width="formLabelWidth">
                      <span>{{ props.row.lastContactTime|timeFormat }}</span>
                    </el-form-item>
                    <el-form-item label="描述" :label-width="formLabelWidth">
                      <span>{{ props.row.description }}</span>
                    </el-form-item>
                    <el-form-item label="经纬度" :label-width="formLabelWidth">
                      <span>{{ props.row.lng }} # {{ props.row.lat }}</span>
                      <el-button type="text" size="default"
                                 @click="open('location',{lng:props.row.lng,lat:props.row.lat})"
                                 icon="el-icon-location"></el-button>
                    </el-form-item>
                    <el-form-item label="地址" :label-width="formLabelWidth">
                      <span>{{ props.row.address }}</span>
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column prop="areaName" label="所属区域" show-overflow-tooltip width="80">
          </el-table-column>
          <el-table-column prop="typeName" label="设备类型" show-overflow-tooltip width="80">
          </el-table-column>
          <el-table-column prop="unitName" label="所在单位" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="equipmentCode" label="设备特征码" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="contactPhone" label="联系电话" show-overflow-tooltip width="120">
          </el-table-column>
          <el-table-column prop="installTime" label="安装时间" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.installTime|timeFormat }}
            </template>
          </el-table-column>
          <el-table-column label="经纬度" width="140">
            <template slot-scope="scope">
              <span>{{ scope.row.lng.toFixed(3) }}#{{ scope.row.lat.toFixed(3) }}</span>
              <el-button type="text" icon="el-icon-location" size="default"
                         @click="open('location',{lng:scope.row.lng,lat:scope.row.lat})"></el-button>
            </template>
          </el-table-column>
          <el-table-column prop="lastContactTime" label="状态" show-overflow-tooltip width="80">
            <template slot-scope="props">
              <el-tag type="success" size="small" v-show="props.row.status===1">在线</el-tag>
              <el-tag type="danger" size="small" v-show="props.row.status===0">离线</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="sysFlag" label="同步状态" show-overflow-tooltip width="80">
            <template slot-scope="scope">
              <el-tag type="danger" size="small" v-show="scope.row.syncFlag!==1">未同步</el-tag>
              <el-tag type="success" size="small" v-show="scope.row.syncFlag===1">已同步</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="解绑" placement="top">
                <el-button size="mini" @click="unbind(scope.row.equipmentCode)" type="warning" icon="el-icon-unlock"
                           circle></el-button>
              </el-tooltip>
              <!--              <el-tooltip effect="dark" content="同步" placement="top">-->
              <!--                <el-button type="success" size="mini" icon="el-icon-refresh" circle-->
              <!--                           v-if="scope.row.syncFlag!==1"></el-button>-->
              <!--              </el-tooltip>-->
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="footer">
        <el-pagination @current-change="_initTableData()" :current-page.sync="requestParams.pageNo" :page-size="10"
                       layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </el-dialog>
    <bind-part-dialog :option="option" ref="bindPartDialog" />
  </div>
</template>

<script>
import BindPartDialog from "./BindPartDialog"

export default {
  name: "StationListDialog",
  components: {BindPartDialog},
  data() {
    return {
      parentId: null,
      visible: false,
      searchPopoverVisible: false,
      isLoading: false,
      list: [],
      requestParams: {
        pageNo: 1
      },
      total: 0
    }
  },
  props: {
    option: {
      type: Object,
      default: () => ({
        typeList: []
      })
    }
  },
  watch: {
    visible(v) {
      if (v) {
        this._initTableData()
      }
    }
  },
  methods: {
    async _initTableData() {
      try {
        this.isLoading = true
        const {data: res} = await this.$axios.get('/equipment/list', {params: {...this.requestParams, pageSize: 10}})
        this.isLoading = false
        this.list = res.data.result
        this.total = res.data.total
      } catch (e) {
        this.$message.error(e.msg)
      } finally {
        this.isLoading = false
      }
    },
    show({equipmentCode, id}) {
      this.parentId = id
      this.list = []
      Object.assign(this.requestParams, {stationCode: equipmentCode})
      this.visible = true
    },
    syncAll() {
      this.$axios.post("/equipment/syncSubEquipment/" + this.parentId).then((res) => {
        this.$message.success(res.data.msg)
      })
    }
  }
}
</script>

<style scoped>

</style>
