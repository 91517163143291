<template>
  <el-dialog title="绑定部件" destroy-on-close v-bind="$attrs" :visible.sync="visible" @close="close">
    <el-form :model="form" ref="deviceForm" :rules="rules" label-position="left">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="设备类型" prop="typeId">
            <el-select v-model="form.typeId" placeholder="请选择设备类型" clearable style="float:left">
              <el-option v-for="item in option.typeList" :key="item.value" :label="item.typeName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="地址" prop="address" label-width="50px">
            <el-input v-model="form.address" autocomplete="off" placeholder="请输入单位地址"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="所在单位" prop="unitId">
            <remote-company-selector v-model="form.unitId" />
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="设备特征码" prop="equipmentCode" label-width="92px">
            <el-input v-model="form.equipmentCode" autocomplete="off" placeholder="请输入设备特征码"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="16" :offset="0">
          <el-form-item label="基站特征码" prop="stationCode" label-width="92px">
            <el-input v-model="form.stationCode" autocomplete="off" placeholder="请输入基站特征码"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="安装时间" prop="installTime">
            <el-date-picker v-model="form.installTime" type="datetime" placeholder="选择安装时间" style="float:left" />
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="联系电话" prop="contactPhone" label-width="70px">
            <el-tooltip effect="light" content="多个号码请使用逗号（英文半角）分隔" placement="top">
              <el-input v-model="form.contactPhone" autocomplete="off" placeholder="不填通知将发送至法人联系号码" />
            </el-tooltip>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="20" :offset="0">
          <el-form-item label="描述" prop="description" label-width="70px">
            <el-input type="textarea" v-model="form.description" autocomplete="off"
                      placeholder="请输入描述"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="_add">添 加</el-button>
    </div>
  </el-dialog>
</template>

<script>
import RemoteCompanySelector from "../RemoteCompanySelector";

export default {
  name: "BindPartDialog",
  components: {RemoteCompanySelector},
  data() {
    const validateContactPhone = (rule, value, callback) => {
      if (value === '' || value === undefined) {
        return callback()
      }
      const regx = /^(1\d{10},?)*$/
      //\d{11}
      if (!regx.test(value)) {
        callback(new Error('输入格式有误'));
      }
      else {
        callback()
      }
    };
    return {
      visible: false,
      form: {},
      rules: {
        typeId: [
          {required: true, message: '请选择设备类型', trigger: 'change'}
        ],
        unitId: [
          {required: true, message: '请选择所在单位', trigger: 'change'}
        ],
        equipmentCode: [
          {required: true, message: '请输入设备特征码', trigger: 'change'}
        ],
        stationCode: [
          {required: true, message: '请输入基站特征码', trigger: 'change'}
        ],
        contactPhone: [
          {validator: validateContactPhone}
        ]
      },
    }
  },
  props: {
    option: {
      type: Object,
      default: () => ({
        typeList: []
      })
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    close() {
      this.form={}
    },
    _add() {
      console.log(this.form)
    },
  }
}
</script>

<style scoped>

</style>
