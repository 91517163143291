<template>
  <el-select :value="value" @change="$emit('change',$event)" placeholder="搜素选择安装建筑物" clearable filterable remote
             :remote-method="remoteMethod" style="float:left">
    <el-option v-for="(item,i) in list" :key="item.id" :label="item.buildingName" :value="item.id">
      <span style="float: left">{{ item.buildingName }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{ item.address }}</span>
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "RemoteBuildingSelector",
  data() {
    return {
      options: [],
    }
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: [String, Number],
    echoOption: {
      type: Object,
      default: () => {}
    }
  },
  computed:{
    list(){
      const i=this.options.findIndex(v=>v.id===this.echoOption.id)
      if(i!==-1 || this.echoOption==null){
        return this.options
      }
      return [this.echoOption].concat(this.options)
    }
  },
  methods: {
    async remoteMethod(query) {
      const {data: result} = await this.$axios.get('/building/listAllBuilding', {params: {buildingName: query}})
      this.options = result.data
    },
  }
}
</script>

<style scoped>

</style>
